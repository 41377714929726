<template>
  <div class="login">
    <b-container>
      <b-row class="login-holder text-center" align-v="center">
        <b-col>
          <b-container>
            <b-alert :show="showAccountActivation" variant="success">
              <h4 class="alert-heading">
                {{ $t("register.Congratulations") }}
              </h4>
              <p>
                {{ $t("login.registerSuccessfulInfo") }}
              </p>
            </b-alert>
          </b-container>

          <transition name="slide-fade">
            <b-container class="login-box" v-show="showLogin">
              <div class="wrapper">
                <div class="image">
                  <img src="../assets/circular.svg" alt="circular" />
                </div>
                <div class="text-content">
                  <!-- <span class="tagline ml-3">{{ $t("home.welcome") }}</span> -->
                  <img src="../assets/eos.png" alt="circular" />

                  <h1 class="text-uppercase">{{ $t("login.login") }}</h1>
                </div>
              </div>
              <b-container class="mt-4">
                <b-form @submit.prevent="onSubmit" id="login-form" class="mb-3">
                  <b-form-group>
                    <b-form-input
                      id="username"
                      class="user-input"
                      v-model="form.username"
                      type="text"
                      :placeholder="$t('login.username')"
                      size="lg"
                      trim
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group>
                    <b-form-input
                      id="password"
                      class="user-input"
                      v-model="form.password"
                      type="password"
                      :placeholder="$t('login.password')"
                      size="lg"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-button
                    type="submit"
                    class="login-button"
                    size="lg"
                    variant="primary"
                    >{{ $t("login.login") }}</b-button
                  >
                </b-form>
                <router-link :to="{name: 'Register'}">{{$t('login.registerInstead')}}</router-link>
                <br>
                <router-link :to="{name: 'ResetPassword'}">{{$t('login.forgotPassword')}}</router-link>
              </b-container>
            </b-container>
          </transition>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Login",
  data: () => {
    return {
      form: {},
      showLogin: false,
      message: "",
      showAccountActivation: false,
    };
  },
  computed: {
    ...mapGetters("users", ["getLoggedin"]),
  },
  methods: {
    ...mapActions("users", ["checkLogin", "login", "logout"]),
    ...mapActions("alerts", ["fireAlert", "resetAlert"]),
    onSubmit() {
      this.loginToService(this.form.username, this.form.password);
    },

    loginToService(username, password) {
      this.login([username, password]);
    },

    async activateAccount(token) {
      let tokenChecker = new RegExp(
        /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/i
      );

      if (!tokenChecker.test(token)) {
        // reject request if token has no UUID format
        this.fireAlert([
          this.$t("Error"),
          this.$t("login.invalidToken"),
          "danger",
        ]);
        return false;
      }

      try {
        await axios.post("/ui-api/v1/activate", { token });
      } catch (error) {
        console.error(error);
        return false;
      }

      return true;
    },
  },
  async mounted() {
    // check if user already has a valid token in localStorage and use it, if present!
    this.checkLogin();
    let token = this.$route.query["token"];
    if (token) {
      if (await this.activateAccount(token)) {
        this.showAccountActivation = true;
      } else {
        this.showAccountActivation = false;
        this.fireAlert([
          this.$t("Error"),
          this.$t("login.activationError"),
          "danger",
        ]);
      }
    }

    setTimeout(() => {
      this.showLogin = true;
    }, 200);
  },
};
</script>
<style lang="scss" scoped>
@import "../theme.scss";

.login-holder {
  height: calc(100vh - #{$navbar-height});
}
.login::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
  background-image: url("~@/assets/photovoltaic-bg.jpg");
  width: 100%;
  height: 100%;

  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.login-logo {
  max-height: 20vh;
}
.alert-max-width {
  max-width: 75vw;
}
.login-box {
  max-width: 75vw;
  background: $white;
  padding: 1rem;
  border-radius: 1rem;
}
.user-input {
  // font-size: 2rem;
  text-align: center;
  max-width: 75%;
  margin: auto;
}
@include media-breakpoint-up(sm) {
  .login-button {
    padding: 1rem 5rem;
  }
}

@include media-breakpoint-down(sm) {
  .user-input{
    max-width: 100%;
  }
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  .image {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
  .text-content {
    margin-left: 1rem;
    flex-grow: 2;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    * {
      margin-bottom: 0.5rem;
    }

    img {
      height: 70px;
    }

    .tagline {
      font-family: "leadfont";
      text-transform: uppercase;
    }
  }
  @include media-breakpoint-down(sm) {
    .text-content {
      align-items: center;

      margin-top: 0.5rem;
    }
  }
}
</style>